<template>
  <div class="home">
   <Logo ></Logo>
  </div>
</template>

<script>
// @ is an alias to /src
import Logo from '@/components/Logo.vue'
//import gsap from gsap

export default {
  name: 'Home',
  components: {
   Logo
  }
}
</script>
<style scoped>
  .home{
    display:flex;
    width:100vw;
    height:100vh;
    align-items: center;
    justify-content: center;
  }
 
</style>
