<template>
  <div class="title">
   
  <div class="titleGR" :style="'opacity:'+vrOpacity">
      <img src="../assets/images/Logo_VR.png" width="100%">
    </div>
    <div class="titleGR" :style="'opacity:'+drOpacity">
      <img src="../assets/images/Logo_DR.png" width="100%">
    </div>
  </div>
</template>

<script>
export default {
  name: 'Logo',
  props: {
   
  },
   data() {
    return {
      vrOpacity:.1,
      drOpacity:0,
      flickOn:true,
    }
  },
  mounted(){
    setInterval(this.flickerOn,2000)
    setInterval(this.flicker,60)
  },
  methods:{
    flickerOn(){
      if(Math.random()*10>5){
        this.flickOn=true;
      }else{
        this.flickOn=false;
      }
    },
    flicker(){
      if(this.flickOn){
        this.vrOpacity=Math.max(.7,Math.floor(Math.random()*10)*.5)
        this.drOpacity=.4+Math.abs(1-Math.floor(Math.random()*5)*.5)
      }else{
        this.vrOpacity=0;
        this.drOpacity=0
      }
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
   .title{
    position:relative;
    width:100%;
    height:calc(300/1130*100vw);
    font-size:200px;
    opacity:.2;
  }
  .titleGR{
    position:absolute;
     width:100%;
  }
</style>
