import Vue from 'vue'
import Vuex from 'vuex'
import { Store } from 'vuex';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    videos:null,
    override:false,
  },
  mutations: {
    setVideos(state,payload){
      state.videos=payload

    },
    setOverride(state,payload){
      state.override=payload

    },
  },
  actions: {
    getVideos({dispatch,commit, getters }){
      firebase.firestore().collection("videos").get().then(querySnapshot => {
        if(!querySnapshot.empty){
          var vids=[]
          
          querySnapshot.forEach(doc => {
           let data=doc.data()
           vids.push(data)
           // commit("setVideos",data)
           if(data.override){
            commit("setOverride",data)
           }
           
        })
        commit("setVideos",vids)
      }else{
        console.log("QUERY NO DATA")
      }
    }).catch(err => {
      console.log('Error logged', err);
      })
      
    },
  },
  modules: {
  },
  getters:{
    getVideos(state){
      return state.videos
    },
    getOverride(state){
      return state.override
    }
  }
})
