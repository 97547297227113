import Vue from 'vue'
import App from './App.vue'

import router from './router'
import store from './store'
import firebase from 'firebase/compat/app'
import 'firebase/firestore';
import "firebase/analytics";


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: function (h) { return h(App) },
  created(){
    const firebaseConfig = {
      apiKey: "AIzaSyAhGjvgioBtPWoAA7TERgNardNvTWy_Y9Q",
      authDomain: "vanramsey.firebaseapp.com",
      projectId: "vanramsey",
      storageBucket: "vanramsey.appspot.com",
      messagingSenderId: "563823651301",
      appId: "1:563823651301:web:91926bd78f47f7697e270c",
      //measurementId: "G-7FGS9X1R4K"
      measurementId: "G-YJ8K9XQPHM"
      
    };
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig)
      firebase.analytics().logEvent('notification_received');
    }
    firebase.auth().onAuthStateChanged(user => {
  
    if (user) {
      this.$store.dispatch('autoSignIn', user)
    }
  })
  }
}).$mount('#app')
