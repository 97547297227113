import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/-',
    name: 'Home',
    component: Home
  },
  {
    path: '/',
    name: 'Video',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Video.vue')
    }
  },
  {
    path: '/vimeo',
    name: 'Vimeo',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Vimeo.vue')
    }
  },
  {
    path: '/about',
    name: 'About',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/About.vue')
    }
  },
  {
    path: '/codetest',
    name: 'CodeTest',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/CodeTest.vue')
    }
  },
  
  

  { 
    path: "*",
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Video.vue')
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
